import merge from "deepmerge"
import gray from "gray-percentage"
import defaultThemeTypography from "gatsby-theme-blog/src/gatsby-plugin-theme-ui/typography"
import { MOBILE_MEDIA_QUERY } from "typography-breakpoint-constants"

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray
console.log(defaultThemeTypography)
const a = merge(defaultThemeTypography, {
  fonts: {
    body: "'Poppins', 'sans-serif'",
    heading: "'Roboto', 'sans-serif'!important,"
  },
  typography: {
    options: {
      bodyFontFamily: ['Poppins', 'sans-serif'],
      headerFontFamily: ['Roboto', 'sans-serif'],
    },
  }
}, { arrayMerge: overwriteMerge })

console.log(a);
export default a;
