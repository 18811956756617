// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-theme-blog-core-src-templates-post-query-js": () => import("./../../node_modules/gatsby-theme-blog-core/src/templates/post-query.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-core-src-templates-post-query-js" */),
  "component---node-modules-gatsby-theme-blog-core-src-templates-posts-query-js": () => import("./../../node_modules/gatsby-theme-blog-core/src/templates/posts-query.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-core-src-templates-posts-query-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-speaking-js": () => import("./../../src/pages/speaking.js" /* webpackChunkName: "component---src-pages-speaking-js" */),
  "component---src-pages-writing-js": () => import("./../../src/pages/writing.js" /* webpackChunkName: "component---src-pages-writing-js" */)
}

